<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.training.training_requests") }}
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          single-expand
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template #item="row">
            <tr :active="row.selected">
              <td>
                <employee-profile :employee="row.item.trainingRequest.employee" />
              </td>
              <td>
                <v-list-item class="pa-0">
                  <v-list-item-avatar
                    size="30"
                    class="white--text body-1 font-weight-medium"
                    :color="row.item.trainingRequest.training.color"
                  >
                    {{ row.item.trainingRequest.training.name.substring(0, 2).toUpperCase() }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="body-1">
                      {{ row.item.trainingRequest.training.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </td>
              <td>
                {{ row.item.trainingRequest.requestDateTime | formattedDate }}
              </td>
              <td>{{ row.item.trainingRequest.detail || "-" }}</td>
              <td>
                <request-status-label :status="row.item.trainingRequest.trainingRequestStatus" />
              </td>
              <td class="text-center">
                <v-btn
                  :disabled="isRequestCancelButtonDisabled(row.item.trainingRequest.trainingRequestStatus)"
                  small
                  color="primary"
                  class="font-weight-bold"
                  depressed
                  @click.stop="openRequestCancelDialog(row.item.trainingRequest.id)"
                >
                  <v-icon left> mdi-calendar-remove </v-icon>
                  {{ $t("hr.training.cancel_request") }}
                </v-btn>
              </td>
              <td class="text-right">
                <v-btn
                  small
                  depressed
                  color="primary"
                  dark
                  class="font-weight-bold"
                  @click.stop="openRequestApprovalDialog(row.item.id)"
                >
                  {{ $t("buttons.action") }}
                </v-btn>
              </td>
              <td class="text-right">
                <v-btn icon @click.stop.prevent="row.expand(!row.isExpanded)">
                  <v-icon v-if="row.isExpanded">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template #expanded-item="{ headers, item }">
            <tr>
              <td :colspan="headers.length" class="pa-0">
                <approval-history-table :item="item.trainingRequest" processKey="trainingRequestProcesses" />
              </td>
            </tr>
          </template>
        </v-data-table>
        <request-cancel-dialog v-model="requestCancelDialogStatus" :request-id="requestId" @success="onRefreshData" />
        <request-approval-dialog
          v-model="requestApprovalDialogStatus"
          :request-id="requestId"
          @success="onRefreshData"
        />
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { PENDING_TRAINING_REQUESTS } from "./query";

  export default {
    name: "PendingTrainingRequests",
    components: {
      RequestStatusLabel: () => import("@/components/global/request-status-label"),
      RequestCancelDialog: () => import("./components/RequestCancelDialog"),
      RequestApprovalDialog: () => import("./components/RequestApprovalDialog")
    },
    data: (vm) => ({
      requestApprovalDialogStatus: false,
      requestCancelDialogStatus: false,
      requestId: null,
      table: {
        loading: false,
        data: [],
        selected: [],
        headers: [
          {
            text: vm.$t("global.employee"),
            value: null,
            align: "start",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.training.training"),
            value: null,
            align: "start",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.training.request_date"),
            value: "requestDateTime",
            align: "start",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.detail"),
            value: "",
            align: "start",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.status"),
            value: "trainingRequestStatus",
            align: "start",
            sortable: true,
            class: ["primary--text"]
          },
          {
            align: "end",
            sortable: false,
            width: "1%"
          },
          {
            align: "end",
            sortable: false,
            width: "1%"
          },
          {
            align: "end",
            sortable: false,
            width: "1%"
          }
        ]
      }
    }),
    methods: {
      isRequestCancelButtonDisabled(status) {
        return ["CANCELLED", "DENIED"].indexOf(status) > -1;
      },
      openRequestApprovalDialog(requestId) {
        this.requestId = requestId;
        this.requestApprovalDialogStatus = true;
      },
      openRequestCancelDialog(requestId) {
        this.requestId = requestId;
        this.requestCancelDialogStatus = true;
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: PENDING_TRAINING_REQUESTS,
            variables: {
              id: this.$store.state.auth.user.id
            },
            fetchPolicy: "no-cache"
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              this.table.data = data.employee.pendingApprovalTrainingRequests || [];
            }
          })
          .catch((e) => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      },
      onCreate() {
        this.$router.replace({ name: "training_request_create" });
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>
